/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Modal, Row, Col } from 'react-bootstrap';
import './ViewAllQuotations.css';
import SearchQuotations from '../features/SearchServices';
import PaginationComponent from '../features/Pagination';
import BASE_URL from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPrint, FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import logo from '../assets/logo512.png';

const ViewAllQuotationsLM = () => {
  const [quotations, setQuotations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [quotationsPerPage] = useState(5);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentMonth, setCurrentMonth] = useState('');
  const printRef = useRef();

  useEffect(() => {
    fetchQuotations();
    setCurrentMonth(getCurrentMonth());
  }, []);

  const getCurrentMonth = () => {
    const date = new Date();
    const monthNames = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"
    ];
    return monthNames[date.getMonth()];
  };

  const fetchQuotations = async () => {
    try {
      const response = await fetch(`${BASE_URL}/quotation`);
      if (!response.ok) {
        throw new Error('Failed to fetch quotations');
      }
      const quotationsData = await response.json();

      const currentMonthNumber = new Date().getMonth(); // Get the current month (0-11)
      const currentYear = new Date().getFullYear(); // Get the current year

      const quotationsWithCustomers = await Promise.all(
        quotationsData.map(async (quotation) => {
          try {
            if (quotation.customer_id) {
              const customerResponse = await fetch(`${BASE_URL}/customers/${quotation.customer_id}`);
              if (!customerResponse.ok) {
                throw new Error(`Failed to fetch customer for customer ID ${quotation.customer_id}`);
              }
              const customerData = await customerResponse.json();

              if (customerData.data && customerData.data.firstName && customerData.data.lastName) {
                return {
                  ...quotation,
                  customerName: `${customerData.data.firstName} ${customerData.data.lastName}`,
                  customerEmail: `${customerData.data.email}`,
                  customerContactNumber: `${customerData.data.contactNo}`,
                };
              } else {
                throw new Error(`Invalid customer data for customer ID ${quotation.customer_id}`);
              }
            } else {
              throw new Error(`Customer ID not found for quotation ID ${quotation._id}`);
            }
          } catch (error) {
            console.error('Error fetching customer data:', error);
            return {
              ...quotation,
              customerName: 'Unknown'
            };
          }
        })
      );

      // Filter quotations to only include those created in the current month and year
      const currentMonthQuotations = quotationsWithCustomers.filter(quotation => {
        const createdDate = new Date(quotation.createdAtQuotation);
        return createdDate.getMonth() === currentMonthNumber && createdDate.getFullYear() === currentYear;
      });
      currentMonthQuotations.sort((a, b) => new Date(b.createdAtQuotation) - new Date(a.createdAtQuotation));
      setQuotations(currentMonthQuotations);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const filteredQuotations = quotations.filter(quotation =>
    (quotation.type && quotation.type.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (quotation.vehicle_no && quotation.vehicle_no.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (quotation.desc && quotation.desc.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const indexOfLastQuotation = currentPage * quotationsPerPage;
  const indexOfFirstQuotation = indexOfLastQuotation - quotationsPerPage;
  const currentQuotations = filteredQuotations.slice(indexOfFirstQuotation, indexOfLastQuotation);

  const generateInvoice = (id) => {
    const quotation = quotations.find(quotation => quotation.quotation_id === id);
    if (quotation) {
      setSelectedQuotation(quotation);
      setShowInvoiceModal(true);
    }
  };

  const handleCloseInvoice = () => {
    setSelectedQuotation(null);
    setShowInvoiceModal(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}/quotation/delete/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        const updatedQuotations = quotations.filter(quotation => quotation.quotation_id !== id);
        setQuotations(updatedQuotations);
        toast.success('Quotation deleted successfully');
      } else {
        throw new Error('Failed to delete quotation');
      }
    } catch (error) {
      console.error('Error deleting quotation:', error);
      toast.error('Failed to delete quotation. Please try again.');
    }
  };

  const handlePrintInvoice = () => {
    const printContents = printRef.current.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  return (
    <div className="view-all-quotations">
      <h2 className="text-center">All Quotations - {new Date().toLocaleString('default', { month: 'long' })} {Date().getFullYear}</h2>

      <SearchQuotations searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Quotation ID</th>
            <th>Type</th>
            <th>Vehicle Number</th>
            <th>Created Date</th>
            <th>Total Amount</th>
            <th>Customer Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentQuotations.map(quotation => (
            <tr key={quotation.quotation_id}>
              <td>{quotation.quotation_id}</td>
              <td>{quotation.type}</td>
              <td>{quotation.vehicle_no}</td>
              <td>{new Date(quotation.createdAtQuotation).toLocaleString()}</td>
              <td>Rs. {quotation.totalAmount}.00</td>
              <td>{quotation.customerName}</td>
              <td>
                <Link to={`/update-quotation/${quotation.quotation_id}`} className="action-button">
                  <Button variant="warning" className="mr-2"><FaEdit /></Button>
                </Link>
                <Link to={`/view-quotation/${quotation.quotation_id}`} className="action-button">
                  <Button variant="primary" className="mr-2"><FaEye /></Button>
                </Link>
                <Button
                  variant="success"
                  onClick={() => generateInvoice(quotation.quotation_id)}
                  className="action-button"
                >
                  <FaPrint className="mr-2" />
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(quotation.quotation_id)}
                  className="action-button"
                >
                  <FaTrashAlt />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Row className="justify-content-center">
        <Col xs="auto">
          <PaginationComponent
            currentPage={currentPage}
            totalPages={Math.ceil(filteredQuotations.length / quotationsPerPage)}
            onPageChange={handlePageChange}
          />
        </Col>
      </Row>

      <Modal show={showInvoiceModal} onHide={handleCloseInvoice} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Quotation Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div ref={printRef} className="invoice-container shadow-lg p-3 mb-5 bg-white rounded" style={{ border: "1px solid #007bff" }}>
            {selectedQuotation && (
              <div>
                <div className="header-section">
                  <Row style={{ backgroundColor: "#007bff", color: "white", padding: "20px 0", marginBottom: "20px" }}>
                    <Col className="text-center">
                      <img src={logo} alt="Logo" style={{ width: "200px" }} />
                      <p style={{ fontSize: "34px", margin: "10px 0 0 0" }}>NexGen AutoCare</p>
                    </Col>
                  </Row>
                  <Row><p style={{ fontSize: "30px", textAlign: "center" }}>Quotation</p></Row>
                  <hr/>
                  <div className="d-flex justify-content-between">
                    <div className="quotation-details">
                      <h5>Quotation ID: {selectedQuotation.quotation_id}</h5>
                      <h6>Customer: {selectedQuotation.customerName}</h6>
                      <h6>Vehicle Number: {selectedQuotation.vehicle_no}</h6>
                      <h6>Description: {selectedQuotation.desc}</h6>
                      <h6>Created Date: {new Date(selectedQuotation.createdAtQuotation).toLocaleString()}</h6>
                    </div>
                    <div className="customer-details">
                      <h6>Email: {selectedQuotation.customerEmail}</h6>
                      <h6>Contact Number: {selectedQuotation.customerContactNumber}</h6>
                    </div>
                  </div>
                </div>

                <hr/>

                <div className="item-details">
                  <h5>Item Details</h5>
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Task</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedQuotation.tasks.map((task, index) => (
                        <tr key={index}>
                          <td>{task.task}</td>
                          <td>Rs. {task.amount}.00</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                <hr/>

                <div className="total-amount">
                  <h5>Total Amount: Rs. {selectedQuotation.totalAmount}.00</h5>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseInvoice}>Close</Button>
          <Button variant="primary" onClick={handlePrintInvoice}>Print</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewAllQuotationsLM;