import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import BASE_URL from '../config'; // Adjust path as needed
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CreateCustomer.css';

const CreateCustomer = ({ userData }) => {
  const initialInputs = {
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    nic: '',
    contactNo: ''
  };

  const [inputs, setInputs] = useState({ ...initialInputs });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BASE_URL}/customers`, {
        ...inputs,
        staffId: userData.staff_id // Include staffId in the request payload
      });
      console.log('Customer created:', response.data);
      setInputs({ ...initialInputs });
      navigate('/view-all-customers');
      toast.success('Customer added successfully');
    } catch (error) {
      console.error('Error adding customer:', error);
      toast.error('Failed to add customer. Please try again.');
    }
  };

  return (
    <Container fluid className="create-customer">
      <h2>Create Customer</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
        <Col md={4}>
        <Form.Group controlId="title">
  <Form.Label>Title</Form.Label>
  <Form.Control
    as="select"
    name="title"
    value={inputs.title}
    onChange={handleChange}
    required
  >
    <option value="Mr.">Mr.</option>
      <option value="Ms.">Ms.</option>
      <option value="Mrs.">Mrs.</option>
      <option value="Dr.">Dr.</option>
      <option value="Prof.">Prof.</option>
      <option value=" ">None</option>
  </Form.Control>
</Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="firstName">
              <Form.Label>First Name:</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={inputs.firstName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="lastName">
              <Form.Label>Last Name:</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={inputs.lastName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col md={6}>
            <Form.Group controlId="email">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={inputs.email}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="nic">
              <Form.Label>NIC:</Form.Label>
              <Form.Control
                type="text"
                name="nic"
                value={inputs.nic}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col md={6}>
            <Form.Group controlId="contactNo">
              <Form.Label>Contact No:</Form.Label>
              <Form.Control
                type="text"
                name="contactNo"
                value={inputs.contactNo}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Button style={{backgroundColor: "#19325f"}} type="submit" className="mt-3">
            Add Customer
          </Button>
        </Row>
      </Form>
    </Container>
  );
};

export default CreateCustomer;