/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Modal } from 'react-bootstrap';
import './ViewAllQuotations.css';
import SearchQuotations from '../features/SearchServices';
import PaginationComponent from '../features/Pagination';
import BASE_URL from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPrint, FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import Invoice from './Invoice';
// Remove this line if ExtraInvoice is not needed
// import ExtraInvoice from './ExtraInvoice';

const ViewAllQuotations = () => {
  const [services, setQuotations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [servicesPerPage] = useState(5);
  const [selectedService, setSelectedService] = useState(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [invoiceType, setInvoiceType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);

  useEffect(() => {
    fetchQuotations();
  }, []);

  const fetchQuotations = async () => {
    try {
      const response = await fetch(`${BASE_URL}/quotation`);
      if (!response.ok) {
        throw new Error('Failed to fetch quotations');
      }
      const quotationsData = await response.json();
      
      const quotationsWithDetails = await Promise.all(
        quotationsData.map(async (quotation) => {
          if (quotation.customer_id) {
            try {
              const customerResponse = await fetch(`${BASE_URL}/customers/${quotation.customer_id}`);
              if (!customerResponse.ok) {
                throw new Error(`Failed to fetch customer with ID ${quotation.customer_id}`);
              }
              const customerData = await customerResponse.json();
              const invoiceResponse = await fetch(`${BASE_URL}/quotation/${quotation.quotation_id}`);
              const invoiceData = await invoiceResponse.json();
              const extraInvoiceResponse = await fetch(`${BASE_URL}/quotation/updatesave/${quotation.quotation_id}`);
              const extraInvoiceData = await extraInvoiceResponse.json();

              return {
                ...quotation,
                customerName: `${customerData.data.title} ${customerData.data.firstName} ${customerData.data.lastName}`,
                customerEmail: customerData.data.email || 'Unknown',
                customerContactNumber: customerData.data.contactNo || 'Unknown',
                invoice: invoiceData || {},
                extraInvoice: extraInvoiceData || {},
              };
            } catch (error) {
              console.error('Error fetching customer data:', error);
              return {
                ...quotation,
                customerName: 'Unknown',
                customerEmail: 'Unknown',
                customerContactNumber: 'Unknown',
                invoice: {},
                extraInvoice: {},
              };
            }
          } else {
            return {
              ...quotation,
              customerName: 'Unknown',
              customerEmail: 'Unknown',
              customerContactNumber: 'Unknown',
              invoice: {},
              extraInvoice: {},
            };
          }
        })
      );

      quotationsWithDetails.sort((a, b) => new Date(b.createdAtService) - new Date(a.createdAtService));
      setQuotations(quotationsWithDetails);
    } catch (error) {
      console.error('Error fetching quotations:', error);
    }
  };

  const filteredQuotations = services.filter(quotation => {
    const invoiceId = quotation.quotation_id || '';
    const customerName = quotation.customerName || '';
    const vehicleNumber = quotation.vehicle_no || '';

    const searchQuery = searchTerm.toLowerCase();

    return (
      invoiceId.toLowerCase().includes(searchQuery) ||
      customerName.toLowerCase().includes(searchQuery) ||
      vehicleNumber.toLowerCase().includes(searchQuery)
    );
  });

  const indexOfLastService = currentPage * servicesPerPage;
  const indexOfFirstService = indexOfLastService - servicesPerPage;
  const currentQuotations = filteredQuotations.slice(indexOfFirstService, indexOfLastService);
  const totalPages = Math.ceil(filteredQuotations.length / servicesPerPage);

  const generateInvoice = (id, type) => {
    const quotation = services.find(quotation => quotation.quotation_id === id);
    if (quotation) {
      setSelectedService(quotation);
      setInvoiceType(type);
      setShowInvoiceModal(true);
    }
  };

  const handleCloseInvoice = () => {
    setSelectedService(null);
    setShowInvoiceModal(false);
    setInvoiceType('');
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleShowDeleteModal = (id) => {
    setServiceToDelete(id);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setServiceToDelete(null);
    setShowDeleteModal(false);
  };

  const handleDelete = async () => {
    if (!serviceToDelete) return;

    try {
      const response = await fetch(`${BASE_URL}/quotation/delete/${serviceToDelete}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        const updatedQuotations = services.filter(quotation => quotation.quotation_id !== serviceToDelete);
        setQuotations(updatedQuotations);
        toast.success('Quotation deleted successfully');
      } else {
        throw new Error('Failed to delete quotation');
      }
    } catch (error) {
      console.error('Error deleting quotation:', error);
      toast.error('Failed to delete quotation. Please try again.');
    } finally {
      handleCloseDeleteModal();
    }
  };

  return (
    <div className="view-all-quotations">
      <h2 className="text-center">All Quotations</h2>
      <SearchQuotations searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Service ID</th>
            <th>Vehicle Number</th>
            <th>Created Date</th>
            <th>Total Amount</th>
            <th>Customer Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentQuotations.map(quotation => (
            <tr key={quotation.quotation_id}>
              <td>{quotation.quotation_id}</td>
              <td>{quotation.vehicle_no}</td>
              <td>{new Date(quotation.createdAtService).toLocaleString()}</td>
              <td>Rs. {quotation.totalExtraAmount}.00</td>
              <td>{quotation.customerName}</td>
              <td>
                <Link to={`/update-quotation/${quotation.quotation_id}`} className="action-button">
                  <Button variant="warning" className="mr-2"><FaEdit /></Button>
                </Link>
                <Link to={`/view-quotation/${quotation.quotation_id}`} className="action-button">
                  <Button variant="primary" className="mr-2"><FaEye /></Button>
                </Link>
                <Button
                  variant="danger"
                  onClick={() => handleShowDeleteModal(quotation.quotation_id)}
                  className="action-button"
                >
                  <FaTrashAlt />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      {showInvoiceModal && (
        <Modal show={showInvoiceModal} onHide={handleCloseInvoice}>
          <Modal.Header closeButton>
            <Modal.Title>Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {invoiceType === 'invoice' && selectedService && (
              <Invoice quotation={selectedService} />
            )}
            {/* Remove or add other invoice types if needed */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseInvoice}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this quotation?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default ViewAllQuotations;