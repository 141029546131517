import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaEye, FaToggleOn, FaToggleOff, FaTrash } from 'react-icons/fa';
import PaginationComponent from '../features/Pagination';
import SearchServices from '../features/SearchServices';
import BASE_URL from '../config';
import axios from 'axios';
import './ViewAllStaff.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingScreen from '../features/LoadingScreen';

const ViewAllStaff = () => {
  const [staff, setStaff] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [staffPerPage] = useState(5); // Number of staff per page
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    fetchStaff();
  }, []);

  const fetchStaff = async () => {
    try {
      const response = await fetch(`${BASE_URL}/staff`);
      if (!response.ok) {
        throw new Error('Failed to fetch staff');
      }
      const data = await response.json();
      data.sort((a, b) => new Date(b.createdAtCustomer) - new Date(a.createdAtCustomer));
      setStaff(data); // Assuming 'data' holds the array of staff
      setLoading(false); // Update loading state once data is fetched
    } catch (error) {
      console.error('Error fetching staff:', error);
      setError(error.message || 'Failed to fetch staff');
      setLoading(false); // Update loading state on error
    }
  };

  const toggleStatus = async (id) => {
    try {
      const staffToUpdate = staff.find(staff => staff.staff_id === id);
      const updatedStatus = staffToUpdate.staff_status === 'Active' ? 'Inactive' : 'Active';

      const response = await axios.put(`${BASE_URL}/staff/update_staff_status/${id}`, {
        staff_status: updatedStatus
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.status !== 200) {
        throw new Error('Failed to update staff status');
      }

      const updatedStaff = staff.map(staff =>
        staff.staff_id === id ? { ...staff, staff_status: updatedStatus } : staff
      );
      setStaff(updatedStaff);

      // Display success toast message
      toast.success(`Staff status updated successfully: ${updatedStatus}`);
    } catch (error) {
      console.error('Error toggling staff status:', error);
      // Display error toast message
      toast.error('Failed to update staff status. Please try again.');
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}/staff/delete/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        // Remove the deleted staff from the state
        const updatedStaff = staff.filter(staff => staff.staff_id !== id);
        setStaff(updatedStaff);
        console.log('Staff deleted successfully');
        // Display success toast message
        toast.success('Staff deleted successfully');
      } else {
        console.error('Failed to delete staff');
        // Display error toast message
        toast.error('Failed to delete staff. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting staff:', error);
      // Display error toast message
      toast.error('Failed to delete staff. Please try again.');
    }
  };

  // Ensure staff is defined before filtering
  const filteredStaff = staff && staff.filter(staff =>
    (staff.firstName && staff.firstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (staff.lastName && staff.lastName.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (staff.username && staff.username.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Logic to calculate current staff on current page
  const indexOfLastStaff = currentPage * staffPerPage;
  const indexOfFirstStaff = indexOfLastStaff - staffPerPage;

  // Ensure filteredStaff is defined before slicing
  const currentStaff = filteredStaff && filteredStaff.slice(indexOfFirstStaff, indexOfLastStaff);

  // Ensure filteredStaff is defined before calculating totalPages
  const totalPages = filteredStaff ? Math.ceil(filteredStaff.length / staffPerPage) : 0;

  // Loading state
  if (loading) {
    return <LoadingScreen />;
  }

  // Error state
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="view-all-staff">
      <h2 className="text-center">All Staff</h2>

      {/* SearchStaff component for search input */}
      <SearchServices searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Staff ID</th>
            <th>First Name</th>
            <th>Username</th>
            <th>Contact No</th>
            <th>Status</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentStaff && currentStaff.map(staff => (
            <tr key={staff.staff_id}>
              <td>{staff.staff_id}</td>
              <td>{staff.firstName}</td>
              <td>{staff.username}</td>
              <td>{staff.contactNo || '-'}</td>
              <td>{staff.staff_status || '-'}</td>
              <td>{new Date(staff.createdAtStaff).toLocaleString()}</td>
              <td>
                <Link to={`/update-staff/${staff.staff_id}`} className="action-button">
                  <Button variant="warning" className="mr-2">
                    <FaEdit />
                  </Button>
                </Link>
                <Link to={`/view-staff/${staff.staff_id}`} className="action-button">
                  <Button variant="primary" className="mr-2">
                    <FaEye />
                  </Button>
                </Link>
                <Button
                  variant="info"
                  onClick={() => toggleStatus(staff.staff_id)}
                  className="action-button"
                >
                  {staff.staff_status === 'Active' ? (
                    <>
                      <FaToggleOn />
                    </>
                  ) : (
                    <>
                      <FaToggleOff />
                    </>
                  )}
                </Button>
                {staff.username !== 'admin' && (
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(staff.staff_id)}
                    className="action-button mr-2"
                  >
                    <FaTrash />
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Centered pagination */}
      <Row className="justify-content-center">
        <Col xs="auto">
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ViewAllStaff;