import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Card, Container, Table } from 'react-bootstrap';
import './ViewQuotation.css';
import BASE_URL from '../config';

const ViewQuotation = () => {
  const { id } = useParams();
  const [quotation, setQuotation] = useState(null);
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    const fetchQuotationAndCustomer = async () => {
      try {
        const quotationResponse = await fetch(`${BASE_URL}/quotation/${id}`);
        if (!quotationResponse.ok) {
          throw new Error('Failed to fetch quotation');
        }
        const quotationData = await quotationResponse.json();
        setQuotation(quotationData);

        if (quotationData && quotationData.customer_id) {
          const customerResponse = await fetch(`${BASE_URL}/customers/${quotationData.customer_id}`);
          if (!customerResponse.ok) {
            throw new Error('Failed to fetch customer');
          }
          const customerData = await customerResponse.json();
          setCustomer(customerData.data);
        } else {
          throw new Error('Customer ID not found in quotation data');
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchQuotationAndCustomer();
  }, [id]);

  return (
    <Container className="view-quotation-container">
      <h2 className="page-title">Quotation Details</h2>
      {quotation && customer ? (
        <Row>
          <Col md={6}>
            <Card className="details-card">
              <Card.Body>
                <Card.Title>Quotation Information</Card.Title>
                <hr />
                <Table>
                  <tbody>
                    <tr>
                      <td><strong>Quotation ID</strong></td>
                      <td>{quotation.quotation_id}</td>
                    </tr>
                    <tr>
                      <td><strong>Type</strong></td>
                      <td>{quotation.type}</td>
                    </tr>
                    <tr>
                      <td><strong>Vehicle Number</strong></td>
                      <td>{quotation.vehicle_no}</td>
                    </tr>
                  </tbody>
                </Table>
                <Table>
                <tr>
                      <td style={{textAlign: "center"}}>{quotation.desc}</td>
                    </tr>
                </Table>
                <Table>
                <tr>
                      <td><strong>Total Amount</strong></td>
                      <td>Rs. {quotation.totalAmount}.00</td>
                    </tr>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="details-card">
              <Card.Body>
                <Card.Title>Customer Information</Card.Title>
                <hr />
                <Table>
                  <tbody>
                    <tr>
                      <td><strong>Customer ID</strong></td>
                      <td>{customer.customer_id}</td>
                    </tr>
                    <tr>
                      <td><strong>First Name</strong></td>
                      <td>{customer.firstName}</td>
                    </tr>
                    <tr>
                      <td><strong>Last Name</strong></td>
                      <td>{customer.lastName}</td>
                    </tr>
                    <tr>
                      <td><strong>Email</strong></td>
                      <td>{customer.email}</td>
                    </tr>
                    <tr>
                      <td><strong>NIC</strong></td>
                      <td>{customer.nic}</td>
                    </tr>
                    <tr>
                      <td><strong>Contact No</strong></td>
                      <td>{customer.contactNo}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <p className="loading-message">Loading...</p>
      )}
    </Container>
  );
};

export default ViewQuotation;