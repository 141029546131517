import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import BASE_URL from '../config'; // Adjust path as needed
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './CreateStaff.css';

const CreateStaff = ({userData}) => {
  const initialInputs = {
    firstName: '',
    lastName: '',
    email: '',
    nic: '',
    contactNo: '',
    username: '',
    password: ''
  };

  const [inputs, setInputs] = useState({ ...initialInputs });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BASE_URL}/staff`, {
        firstName: inputs.firstName,
        lastName: inputs.lastName,
        email: inputs.email,
        nic: inputs.nic,
        contactNo: inputs.contactNo,
        username: inputs.username,
        password: inputs.password,
        staffId: userData.staff_id
      });
      console.log('Staff created:', response.data);
      setInputs({ ...initialInputs });
      toast.success('Staff created successfully');
      navigate('/view-all-staff');
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast.error('Username already exists. Please choose a different username.');
      } else {
        console.error('Error adding staff:', error);
        toast.error('Failed to add staff. Please try again.');
      }
    }
  };

  return (
    <Container fluid className="create-staff">
      <h2>Create Staff</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="firstName">
              <Form.Label>First Name:</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={inputs.firstName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="lastName">
              <Form.Label>Last Name:</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={inputs.lastName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="email">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={inputs.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="nic">
              <Form.Label>NIC:</Form.Label>
              <Form.Control
                type="text"
                name="nic"
                value={inputs.nic}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="contactNo">
              <Form.Label>Contact No:</Form.Label>
              <Form.Control
                type="text"
                name="contactNo"
                value={inputs.contactNo}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="username">
              <Form.Label>Username:</Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={inputs.username}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="password">
              <Form.Label>Password:</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={inputs.password}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Button style={{backgroundColor: "#19325f"}} type="submit" className="mt-3">
            Add Staff
          </Button>
        </Row>
      </Form>
    </Container>
  );
};

export default CreateStaff;